import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState, appStateFeatureKey } from './app-state.reducer';

const getAppEntriesState = createFeatureSelector<IAppState>(appStateFeatureKey);

export const getAppState = createSelector(getAppEntriesState, (state) => {
  return {
    waitingLogin: state?.waitingLogin
  };
});
