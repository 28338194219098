import { isPlatformBrowser, Location } from '@angular/common';
import { Component, HostBinding, Inject, Injector, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { SettingsService } from '@adscore/adscore-frontend-common';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { DataService } from '@adscore/adscore-frontend-common';
import { ActivatedRoute, Data, Event, NavigationEnd, NavigationError, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { checkIsIOS } from 'adscore/utils/platform.utils';
import { StateListener } from '@adscore/adscore-frontend-common';
import { data } from '@adscore/adscore-frontend-common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  isBrowser: boolean;
  metaTags: HTMLMetaElement[] = [];
  private destroy$ = new Subject<boolean>();

  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.layout.isFixed;
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.layout.isCollapsed;
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.layout.isBoxed;
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.layout.useFullLayout;
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.layout.hiddenFooter;
  }
  @HostBinding('class.layout-h') get horizontal() {
    return this.settings.layout.horizontal;
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.layout.isFloat;
  }
  @HostBinding('class.offsidebar-open') get offsidebarOpen() {
    return this.settings.layout.offsidebarOpen;
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.layout.asideToggled;
  }
  @HostBinding('class.aside-toggled-mobile') get asideToggledMobile() {
    return this.settings.layout.asideToggledMobile;
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.layout.isCollapsedText;
  }

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    public settings: SettingsService,
    private meta: Meta,
    private dataService: DataService,
    private router: Router,
    private title: Title,
    private injector: Injector,
    private location: Location
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  ngOnInit(): void {
    if (!this.isBrowser) {
      return;
    }
    this.dataService.updatables('sessionResponse', true);

    // spawn state listeners
    new StateListener(this.injector);

    if (checkIsIOS()) {
      this.disableIosTextFieldZoom();
    }
  }

  async checkRouterEvent(routerEvent: Event): Promise<void> {
    if (routerEvent instanceof NavigationError) {
      // Inspired by https://stackoverflow.com/a/43685210
      window.setTimeout(() => this.location.replaceState(routerEvent.url));
    }
    if (routerEvent instanceof NavigationEnd) {
      const getData = (obj: ActivatedRoute): Data => {
        if (obj.firstChild) {
          return getData(obj.firstChild);
        }
        return (obj.data as any).value;
      };
      if (!/^\/faq\//.test(routerEvent.urlAfterRedirects)) {
        const pageData = getData(this.router.routerState.root);
        this.title.setTitle(
          // we don't want to get 'Adscore | Adscore' as title
          'title' in pageData && pageData.title !== 'Adscore' ? `${pageData.title} | Adscore` : 'Adscore'
        );
      }
    }

    this.metaTags.forEach((tag) => {
      this.meta.removeTagElement(tag);
    });
    this.metaTags = [];

    const addTags = (arr: MetaDefinition[]) =>
      arr.forEach((tag) => {
        const htmlTag = this.meta.updateTag(tag); // override default ones
        if (htmlTag) {
          this.metaTags.push(htmlTag);
        }
      });

    // base
    const staticMeta = this.dataService.static('meta') as typeof data.meta;
    addTags(staticMeta.default || []);

    if (routerEvent instanceof NavigationEnd) {
      // per URL - they start with `/`
      const url = routerEvent.urlAfterRedirects as keyof typeof data.meta;
      addTags(staticMeta[url] || []);
    }
  }

  disableIosTextFieldZoom = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      const re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content!)) {
        content = content!.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
