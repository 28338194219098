import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { isMobile } from 'adscore/utils/platform.utils';

@Injectable({
  providedIn: 'root'
})
export class MobileGuard {
  // Protects routes, that can only be viewed on mobiles
  private platformId: object = inject(PLATFORM_ID);

  constructor(private router: Router) {}

  canActivate(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (!isMobile()) {
        this.router.navigate(['/']);
        return false;
      }
    }
    return true;
  }
}
