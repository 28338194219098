import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './app.component';
import { QueueService, SharedServicesModule } from '@adscore/adscore-frontend-common';
import { CtaComponent } from 'adscore/components/cta/cta.component';
import { AdscoreMinModule } from 'adscore/adscore-min.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LayoutModule } from './layout/layout.module';
import { StorageService, ValidationService } from '@adscore/adscore-frontend-common';
import { HttpClientModule } from '@angular/common/http';
import { SettingsService } from '@adscore/adscore-frontend-common';
import { SentryService } from '@adscore/adscore-frontend-common';
import { NumberFormatService } from 'adscore/services/number-format.service';
import { PrismService } from 'adscore/services/prism.service';
import { AddsBlockerErrorModalService } from '@adscore/adscore-frontend-common';
import { SupportEmailService, CountryService } from '@adscore/adscore-frontend-common';
import { EmailTokenResolve } from 'adscore/services/email-token.resolve.service';
import { PasswordResetTokenResolve } from 'adscore/services/password-reset-token.resolve.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NoticeService } from '@adscore/adscore-frontend-common';
import { DemoApiFakeBackendInterceptor } from 'interceptors/demo-api.interceptor';
import { BlockedCookiesModalService } from '@adscore/adscore-frontend-common';
import { LoadingChunkErrorHandlerService } from 'services/loading-chunk-error-handler.service';
import { EffectsModule } from '@ngrx/effects';
import { storeEffects } from './store/index.effects';
import { reducers } from './store/index.reducer';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environments';
import { versions } from 'environments/versions';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    CtaComponent,
    AdscoreMinModule.forRoot(),
    SweetAlert2Module.forRoot(),
    ModalModule.forRoot(),
    LayoutModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    StoreModule.forRoot(reducers, {}),
    EffectsModule.forRoot(storeEffects),
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    SharedServicesModule.forRoot({
      buildRevision: versions.buildRevision,
      releaseFileName: 'release-landing.txt',
      mode: 'landing'
    })
  ],
  providers: [
    QueueService,
    ValidationService,
    SettingsService,
    SentryService,
    NumberFormatService,
    PrismService,
    AddsBlockerErrorModalService,
    SupportEmailService,
    CountryService,
    EmailTokenResolve,
    PasswordResetTokenResolve,
    BrowserAnimationsModule,
    StorageService,
    provideClientHydration(),
    BrowserAnimationsModule,
    NoticeService,
    DemoApiFakeBackendInterceptor,
    BlockedCookiesModalService,
    { provide: ErrorHandler, useClass: LoadingChunkErrorHandlerService }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
