<div id="home-sidebar-overlay">
  <div id="home-sidebar-wrapper">
    <!-- sidebar-->
    <nav>
      <app-navigation-bar [mobileMode]="true"></app-navigation-bar>
    </nav>
  </div>
</div>

<div id="page">
  <div id="main" [class.logged-in]="loggedIn">
    <img id="wings-top" alt="wings" src="/assets/img/adscore--header-wing.min.svg" />
    <header id="homepage-header">
      <div class="d-flex align-items-center justify-content-center" id="toggle-and-logo">
        <nav>
          <button aria-label="open menu" type="button" class="nav-link d-sm-none fs-1" (click)="toggleMobileSidebar()">
            <em class="icon-options-vertical align-middle"></em>
          </button>
          <app-navigation-bar [position]="'homepage-header'"></app-navigation-bar>
          <div id="language" class="d-none">
            <a href="#">&#9660; EN</a>
          </div>
        </nav>
        <div id="header--identity-mobile" class="align-items-center my-2" routerLink="/">
          <img alt="adscore logo" src="/assets/img/adscore--logo--w-color--wo-text.min.svg" />
          <span class="text-brand fs-2 lh-1"><span>AD</span>score</span>
        </div>
      </div>
      <div id="top-wing--for-mobile" [inlineSVG]="'/assets/img/adscore--cta--bottom-wing.min.svg'"></div>
      <div id="header--second-level">
        <div class="row no-gutters">
          <div class="col">
            <div id="header--identity">
              <div class="logo" role="img" aria-label="adscore logo"></div>
              <span class="text-brand fs-1-75 lh-1 m-t-1-25"><span>AD</span>score</span>
            </div>
          </div>
          <div class="col">
            <div id="header--login" [class.logged-in]="loggedIn">
              <!-- when logged in -->
              <div class="logged-in-controls" *ngIf="loggedIn; else LoggedOut">
                <div class="row my-2">
                  <div class="col">
                    <a id="button--sign-in" class="button background-primary text-white w-100" href="/dashboard"
                      >Dashboard</a
                    >
                  </div>
                  <div class="col">
                    <a class="button background-white text-secondary w-100 cursor-pointer" (click)="logout()"
                      >Log out</a
                    >
                  </div>
                </div>
              </div>

              <!-- when logged out -->
              <ng-template #LoggedOut>
                <form method="post">
                  <div class="row">
                    <div class="col">
                      <app-input
                        #emailInput
                        [label]="'Email'"
                        [name]="'email'"
                        [type]="'email'"
                        [primaryColor]="true"
                        [modelRef]="email"
                        (enterKeyPress)="enterKeyPress()"
                        [autocomplete]="'email'"
                        data-testid="email-input"
                      ></app-input>
                    </div>
                    <div class="col">
                      <app-input
                        #passwordInput
                        [label]="'Password'"
                        [name]="'password'"
                        [type]="'password'"
                        [primaryColor]="true"
                        [modelRef]="password"
                        (enterKeyPress)="enterKeyPress()"
                        [autocomplete]="'current-password'"
                      ></app-input>
                    </div>
                  </div>
                  <div class="row m-t-1-25">
                    <div class="col">
                      <button
                        id="button--sign-in"
                        class="button cursor-pointer background-primary text-white w-100"
                        (click)="login()"
                        type="button"
                      >
                        Sign In
                      </button>
                    </div>
                    <div class="col">
                      <a
                        class="button background-white text-secondary w-100"
                        (click)="register()"
                        href="/register"
                        data-testid="desktop-register-btn"
                        >Register</a
                      >
                    </div>
                  </div>
                </form>
              </ng-template>

              <div *ngIf="!loggedIn" id="remember-me" class="row m-t-1-25">
                <div class="col">
                  <label class="c-checkbox">
                    <input name="rememberme" [(ngModel)]="rememberMe" type="checkbox" />
                    <fa-icon [icon]="faCheck" class="m-r-0-5" [class.hidden]="!rememberMe"></fa-icon>
                    Remember Me
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Render children here -->
    <router-outlet (activate)="activate()"></router-outlet>

    <footer id="homepage-footer">
      <div class="footer--wing">
        <div class="wing" role="img" aria-label="wings" [inlineSVG]="'/assets/img/adscore--footer-wing.min.svg'"></div>
        <div
          class="logo"
          role="img"
          aria-label="adscore logo"
          [inlineSVG]="'/assets/img/adscore--footer--logo-wo-color--w-outline.min.svg'"
        ></div>
      </div>
      <div class="footer--content background-secondary p-b-2">
        <ng-container>
          <nav class="m-b-1 text-bold d-none d-sm-block">
            <app-navigation-bar [center]="true" [position]="'homepage-footer'"></app-navigation-bar>
          </nav>
          <app-content-divider [dark]="true" class="d-none d-sm-block"></app-content-divider>
        </ng-container>

        <nav id="additional-resources">
          <ul class="nav-bar secondary-menu text-center m-0 p-0 fs-0-7 text-ultra o-0-75">
            <li class="d-none"><a href="#">API</a></li>
            <li>
              <div><a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></div>
            </li>
            <li>
              <div><a routerLink="/tos" routerLinkActive="active">Terms of Service</a></div>
            </li>
            <li>
              <div>
                <button name="forgot password" class="cursor-pointer" (click)="openPasswordResetModal()">
                  Forgot Password
                </button>
              </div>
            </li>
          </ul>
        </nav>
        <div class="copy m-t-1 text-center fs-0-7 text-bold text-white o-0-5">
          &copy; Copyright Adscore Technologies DMCC {{ today | date : 'y' }}. All content and images are copyrighted.
        </div>
      </div>
    </footer>
  </div>
</div>

<ng-container *ngIf="msgs && msgs.length > 0">
  <div class="toasters-container">
    <div *ngFor="let m of msgs" class="toaster alert alert-{{ getMessageLevelName(m.level) }}">
      <app-toast-message [message]="m" (removeMessage)="removeMessage(m)"></app-toast-message>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="(appState$ | async)?.waitingLogin">
  <div id="loading-indicator">
    <div class="center">
      <div class="logo"></div>
      <div id="balls">
        <div class="ball-pulse o-0-5 d-inline-block">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<swal
  #registrationFeedbackSwal
  [title]="swalRegistrationFeedback.title || ''"
  [html]="swalRegistrationFeedback.body || ''"
  [icon]="swalRegistrationFeedback.type || undefined"
  [showCancelButton]="true"
  [confirmButtonColor]="swalRegistrationFeedback.type !== 'success' ? '#962431' : '#5EA540'"
  confirmButtonText="Close"
>
</swal>
