<div class="content-divider" [class.on-dark-background]="dark" [class.filled]="filled" [class.no-logo]="!showLogo">
  <div class="logo" *ngIf="showLogo">
    <img
      alt="Adscore logo"
      *ngIf="!dark"
      src="/assets/img/adscore--logo--wo-color--w-fill--wo-text.min.svg"
      width="34"
      height="32"
      loading="lazy"
    />
    <img
      alt="Adscore logo"
      *ngIf="dark"
      src="/assets/img/adscore--logo--wo-color--w-fill--wo-text-dark.min.svg"
      width="34"
      height="32"
      loading="lazy"
    />
  </div>
</div>
