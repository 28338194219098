// This module shall require only what is required on HomepageComponent

import { ModuleWithProviders, NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';

// new
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RouterModule } from '@angular/router';
import { ContentDividerComponent } from './components/content-divider/content-divider.component';
import { CtaComponent } from './components/cta/cta.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { InputComponent } from '@adscore/adscore-frontend-common';

@NgModule({
  imports: [
    FormsModule,
    InlineSVGModule.forRoot({ baseUrl: '/' }),
    SweetAlert2Module,
    ContentDividerComponent,
    InputComponent,
    CtaComponent,
    MatTooltipModule
  ],
  declarations: [],

  exports: [
    ContentDividerComponent,
    InlineSVGModule,
    InputComponent,
    SweetAlert2Module,
    RouterModule,
    FormsModule,
    CtaComponent,
    MatTooltipModule
  ]
})
export class AdscoreMinModule {
  static forRoot(): ModuleWithProviders<AdscoreMinModule> {
    return {
      ngModule: AdscoreMinModule
    };
  }
}
