import { ActionReducerMap } from '@ngrx/store';
import { appStateReducer, IAppState, appStateFeatureKey } from './app-state';

interface IState {
  [appStateFeatureKey]: IAppState;
}

export const reducers: ActionReducerMap<IState> = {
  [appStateFeatureKey]: appStateReducer
};
