<input class="timepicker-dial__control timepicker-dial__item"
       [ngClass]="{'active': isActive}"
       [ngModel]="time | timeLocalizer: timeUnit: true"
       (ngModelChange)="time = $event"
       [disabled]="disabled"
       (input)="updateTime($event)"
       (keydown)="onInputKeydown($event)"
       (focus)="saveTimeAndChangeTimeUnit($event, timeUnit)"
       readonly
       [ngxMatTimepickerAutofocus]="isActive"
       *ngIf="!isEditable;else editableTemplate">

<ng-template #editableTemplate>
    <input class="timepicker-dial__control timepicker-dial__item timepicker-dial__control_editable"
           [ngClass]="{'active': isActive}"
           [ngModel]="time | ngxMatTimepickerParser: timeUnit | timeLocalizer: timeUnit : true"
           (ngModelChange)="onModelChange($event)"
           [disabled]="disabled"
           (input)="updateTime($event)"
           (keydown)="onInputKeydown($event)"
           (focus)="saveTimeAndChangeTimeUnit($event, timeUnit)"
           [ngxMatTimepickerAutofocus]="isActive"
           type="number"
           min="0"
           [max]="timeUnit === NgxMatTimepickerUnits.HOUR ? 23 : 59"
           >
</ng-template>
