import { of as observableOf, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import session from '../../../../assets/demo-api-responses/session.json';
import accountPartial from '../../../../assets/demo-api-responses/account-partial.json';
import accountFull from '../../../../assets/demo-api-responses/account-full.json';
import user from '../../../../assets/demo-api-responses/user.json';

@Injectable()
class DemoApiInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.endsWith('/demo/session')) {
      return observableOf(new HttpResponse({ status: 200, body: session }));
    }

    if (request.url.endsWith('/demo/account') && request.method === 'GET') {
      return observableOf(new HttpResponse({ status: 200, body: accountPartial }));
    }

    if (request.url.endsWith('/demo/account/0')) {
      return observableOf(new HttpResponse({ status: 200, body: accountFull }));
    }

    if (request.url.endsWith('/demo/user')) {
      return observableOf(new HttpResponse({ status: 200, body: user }));
    }

    // pass through any requests not handled above
    return next.handle(request);
  }
}

export const DemoApiFakeBackendInterceptor = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: DemoApiInterceptor,
  multi: true
};
