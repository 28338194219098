<ul class="m-0 p-0" [class.text-center]="center">
  <ng-container *ngFor="let l of links">
    <li
      *ngIf="l.type === 'link'"
      [class.active]="url === l.href || (l.regex && l.regex.test(url)) || (isFaq === true && l.href?.includes('faq'))"
    >
      <div>
        <a routerLink="{{ l.href }}" (click)="hideSidebarIfNeeded()">{{ l.anchor }}</a>
      </div>
    </li>
    <li *ngIf="l.type === 'group'" [class.active]="isGroupActive(l)" [class.open]="l.open">
      <div [class.open]="l.open" class="sublinks-container">
        <a (click)="toggleOpen(l)" class="cursor-pointer">{{ l.name }}</a>
        <div class="sublinks" [class.footer]="position === 'homepage-footer'" *ngIf="l.open">
          <div class="sublink" *ngFor="let sublink of l.links">
            <a style="box-shadow: none !important" routerLink="{{ sublink.href }}" (click)="hideSidebarIfNeeded()">{{
              sublink.anchor
            }}</a>
          </div>
        </div>
      </div>
    </li>
  </ng-container>
  <li
    class="demo-dashboard-reference"
    *ngIf="(loggedIn$ | async) === false || (loggedIn$ | async) === undefined || (loggedIn$ | async) === null"
  >
    <div>
      <a
        href="/demo-login"
        class="with-bootom-tooltip"
        data-tooltip="Get to see how Adscore works"
        [attr.data-cta]="position === 'homepage-footer' ? 'homepage-footer' : 'homepage-header'"
        data-cta-demo
        >Demo</a
      >
    </div>
  </li>
  <li [class.active]="url === '/contact'">
    <div>
      <a routerLink="/contact" class="with-bootom-tooltip" data-tooltip="Get in touch" (click)="hideSidebarIfNeeded()"
        >Contact</a
      >
    </div>
  </li>
  <div
    *ngIf="(loggedIn$ | async) === false || (loggedIn$ | async) === undefined || (loggedIn$ | async) === null"
    class="d-sm-none"
  >
    <hr />
    <li [class.active]="url === '/sign-in'">
      <a routerLink="/sign-in" (click)="hideSidebarIfNeeded()">Sign in</a>
    </li>
    <li [class.active]="url === '/register'">
      <a routerLink="/register" (click)="hideSidebarIfNeeded()">Register</a>
    </li>
  </div>
</ul>
