import { createReducer, on } from '@ngrx/store';
import * as appStateActions from './app-state.actions';
import produce from 'immer';
import { isNil } from 'lodash-es';

export interface IAppState {
  waitingLogin: boolean;
}

export const appStateFeatureKey = 'app-state';

const initialState: IAppState = {
  waitingLogin: false
} as IAppState;

export const appStateReducer = createReducer<IAppState>(
  initialState,

  on(appStateActions.setAppStateData, (state, action) =>
    produce<IAppState>(state, (newState) => {
      newState.waitingLogin = isNil(action.waitingLogin) ? state.waitingLogin : action.waitingLogin;
    })
  )
);
