import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as appStateActions from './app-state.actions';
import { IAppState } from './app-state.reducer';
import * as appStateSelectors from './app-state.selectors';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  appState$ = this.store.pipe(select(appStateSelectors.getAppState));

  constructor(private store: Store<IAppState>) {}

  setAppStateData(appStateObj: Partial<IAppState>): void {
    this.store.dispatch(appStateActions.setAppStateData(appStateObj));
  }
}
