import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
  imports: [RouterModule],
  standalone: true
})
export class CtaComponent {
  @Input() ctaText: string;
  @Input() buttonText: string;
  @Input() link: string;
  @Input() ctaPosition = '';
}
