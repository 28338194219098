import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdscoreMinModule } from 'adscore/adscore-min.module';
import { LayoutComponent } from './layout.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ResetPasswordModalService, ToastMessageComponent } from '@adscore/adscore-frontend-common';

@NgModule({
  imports: [AdscoreMinModule.forRoot(), CommonModule, FontAwesomeModule, ToastMessageComponent],
  declarations: [LayoutComponent, NavigationBarComponent],
  exports: [],
  providers: [ResetPasswordModalService]
})
export class LayoutModule {}
