import { createAction, props } from '@ngrx/store';

const prefix = '[App State] ';

export const setAppStateData = createAction(
  `${prefix} Set App State Data`,
  props<{
    waitingLogin?: boolean;
  }>()
);
