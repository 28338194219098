import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DataService } from '@adscore/adscore-frontend-common';
import { SettingsService } from '@adscore/adscore-frontend-common';
import { LayoutComponent } from '../layout.component';

interface ILink {
  href?: string;
  anchor?: string;
  type?: 'link' | 'group';
  name?: string;
  regex?: RegExp;
  open?: boolean;
  links?: ILink[];
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  @Input() center = false;
  @Input() position = '';
  @Input() mobileMode = false;
  url: string;
  isFaq = false;
  events$: Subscription;
  links: ILink[] = [];
  activeGroup: ILink | null;
  skipFirstClick: boolean;
  loggedIn$: Observable<boolean>;

  private destroy$ = new Subject<boolean>();

  constructor(private router: Router, public dataService: DataService, public settings: SettingsService) {}

  @HostListener('window:click', ['$event']) closeActiveGroup(ev: PointerEvent) {
    if (this.activeGroup && !this.skipFirstClick) {
      this.activeGroup.open = false;
      this.activeGroup = null;
    }
    if (this.settings.layout.asideToggledMobile && this.mobileMode) {
      const homeSidebarWrapper = document?.querySelector('.aside-toggled-mobile #home-sidebar-wrapper');
      if (homeSidebarWrapper) {
        // HACK
        const sidebarOpen = globalThis.getComputedStyle(homeSidebarWrapper).marginLeft === '0px';
        const target = ev.target as HTMLElement;
        if (
          sidebarOpen &&
          homeSidebarWrapper &&
          !homeSidebarWrapper.contains(target) &&
          !target.classList.contains('icon-options-vertical')
        ) {
          // clicked outside of sidebar - hide sidebar
          this.hideSidebarIfNeeded();
        }
      }
    }
    this.skipFirstClick = false;
  }

  toggleOpen(group: ILink) {
    group.open = !group.open;
    if (group.open) {
      this.activeGroup = group;
      this.skipFirstClick = true;
    }
  }

  isGroupActive(group: ILink) {
    return !!group.links?.find((link) => link.href === this.url);
  }

  private setUrl(newUrl: string): void {
    this.url = newUrl;
    this.url = this.url.split('#')[0].split('?')[0];
    this.isFaq = this.url.includes('faq') ? true : false;
  }

  ngOnInit() {
    this.setUrl(this.router.url);
    this.events$ = this.router.events
      .pipe(
        filter((ev: Event | RouterEvent) => ev instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((ev) => {
        this.setUrl((ev as NavigationEnd).url);
        if ((ev as NavigationEnd).url.indexOf('/faq') !== -1) {
          this.dataService.updatables('isFaq').value = true;
        } else {
          this.dataService.updatables('isFaq').value = false;
        }
      });

    // discover links
    const groups: Record<string, ILink[]> = {};

    for (const routerConfig of this.router.config) {
      if (routerConfig.component === LayoutComponent) {
        // build links from list
        if (routerConfig.children) {
          for (const routerChild of routerConfig.children) {
            if (routerChild.data?.hide) {
              continue;
            }
            // final url
            let url = '/';
            if (routerConfig.path !== '') {
              url = url + routerConfig.path;
            }
            if (routerChild.path !== '') {
              const c = url[url.length - 1] === '/' ? '' : '/';
              url = url + c + routerChild.path;
            }

            // anchor
            const anchor = routerChild.data?.name;

            // TO CHECK: PROVIDES ERRORS
            // if (anchor ?? true) {
            //   continue;
            // }

            if (routerChild.data?.group) {
              groups[routerChild.data.group] = groups[routerChild.data.group] || [];
              groups[routerChild.data.group].push({ href: url, anchor });
            } else if (routerChild.data?.regex) {
              this.links.push({
                type: 'link',
                href: url,
                anchor,
                regex: new RegExp(routerChild.data.regex, routerChild.data.regexFlags)
              });
            } else {
              this.links.push({ type: 'link', href: url, anchor });
            }
          }
        }
        break;
      }
    }

    Object.keys(groups).forEach((k) => {
      if (this.position === 'homepage-footer') {
        groups[k] = groups[k].reverse();
      }
      this.links.push({ type: 'group', name: k, open: false, links: groups[k] });
    });

    this.loggedIn$ = this.dataService.updatables('loggedIn').observable;
  }

  hideSidebarIfNeeded() {
    if (this.mobileMode) {
      this.settings.layout.asideToggledMobile = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
