import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content-divider',
  templateUrl: './content-divider.component.html',
  styleUrls: ['./content-divider.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class ContentDividerComponent {
  @Input() dark = false;
  @Input() filled = false;
  @Input() showLogo = true;
}
