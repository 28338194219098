import { catchError, tap } from 'rxjs/operators';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { DataService } from '@adscore/adscore-frontend-common';
import { of } from 'rxjs';
import { UserService } from '@adscore/adscore-frontend-common';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class EmailTokenResolve implements Resolve<any> {
  isBrowser: boolean;

  constructor(
    private router: Router,
    private dataService: DataService,
    private userService: UserService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const token = route.params.token;
    if (!this.isBrowser) {
      return new Promise((resolve) => {
        resolve(null);
      });
    }

    // Remove asf_key cookie in browser - it was causing problems with code confirmation
    document.cookie = `asf_key=; path=/; domain=.adscore.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    if (!token) {
      this.router.navigate(['/register']);
      return new Promise((resolve) => {
        resolve(null);
      });
    }

    // allow RESEND SMS to work
    this.dataService.set('token', token);

    return this.userService
      .confirmEmailToken(token)
      .pipe(
        tap(() => {
          // handle successful validation, response is stored by the api
          this.dataService.set('show-phone-confirmation-modal', true);
          this.router.navigate(['']);
        })
      )
      .pipe(
        catchError((errors) => {
          this.dataService.set('register-errors', errors);
          this.router.navigate(['/register']);

          return of(true);
        })
      )
      .toPromise();
  }
}
