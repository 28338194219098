// Homepage
import { EmailTokenResolve } from 'adscore/services/email-token.resolve.service';
import { LayoutComponent } from '../layout/layout.component';

import { Routes } from '@angular/router';
import { PasswordResetTokenResolve } from 'adscore/services/password-reset-token.resolve.service';
import { MobileGuard } from 'adscore/guards/mobile.guard';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/home/home.component').then((m) => m.HomeComponent),
        data: {
          name: 'Home',
          title: 'Adscore'
        }
      },
      {
        path: 'pricing',
        loadComponent: () => import('./pages/pricing/pricing.component').then((m) => m.PricingComponent),
        data: {
          name: 'Pricing',
          title: 'Pricing'
        }
      },
      {
        path: 'implementations',
        loadComponent: () =>
          import('./pages/implementations/implementations.component').then((m) => m.ImplementationsComponent),
        data: {
          name: 'Implementations',
          title: 'Implementations'
        }
      },
      {
        path: 'faq',
        loadComponent: () => import('./pages/faq/faq.component').then((m) => m.FaqComponent),
        data: {
          name: 'FAQ',
          hide: false,
          title: 'FAQ'
        }
      },
      {
        path: 'faq/:slug',
        loadComponent: () => import('./pages/faq/faq-answer/faq-answer-component').then((m) => m.FaqAnswerComponent),
        data: {
          name: 'FAQ',
          hide: true,
          title: 'FAQ'
        }
      },
      {
        path: 'faq/section/:sectionSlug',
        loadComponent: () => import('./pages/faq/faq-section/faq-section.component').then((m) => m.FaqSectionComponent),
        data: {
          name: 'FAQ',
          hide: true,
          title: 'FAQ'
        }
      },
      {
        path: 'tech-documentation',
        loadComponent: () => import('./pages/tech-docs/tech-docs.component').then((m) => m.TechDocsComponent),
        data: {
          name: 'Documentation',
          title: 'Documentation',
          hide: false
        }
      },
      {
        path: 'contact',
        loadComponent: () => import('./pages/contact/contact.component').then((m) => m.ContactComponent),
        data: {
          name: 'Contact',
          title: 'Contact',
          hide: true
        }
      },
      {
        path: 'register',
        loadComponent: () => import('./pages/register/register.component').then((m) => m.RegisterComponent),
        data: {
          name: 'Register',
          hide: true,
          title: 'Register'
        }
      },
      {
        path: 'sign-in',
        canActivate: [MobileGuard],
        loadComponent: () => import('./pages/sign-in/sign-in.component').then((m) => m.SignInComponent),
        data: {
          name: 'Sign in',
          hide: true,
          title: 'Sign in'
        }
      },
      {
        path: 'tos',
        loadComponent: () => import('./pages/tos/tos.component').then((m) => m.TosComponent),
        data: {
          name: 'Terms of Service',
          hide: true,
          title: 'Terms of Service'
        }
      },
      {
        path: 'privacy-policy',
        loadComponent: () =>
          import('./pages/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
        data: {
          name: 'Privacy Policy',
          hide: true,
          title: 'Privacy Policy'
        }
      },
      {
        path: 'ad-fraud-detection-for-ad-networks',
        loadComponent: () =>
          import('./pages/ad-fraud-detection-for-ad-networks/ad-fraud-detection-for-ad-networks.component').then(
            (m) => m.AdFraudDetectionForAdNetworksComponent
          ),
        data: {
          name: 'Ad Fraud Detection for Ad Networks',
          title: 'Ad Fraud Detection for Ad Networks',
          group: 'Use Cases'
        }
      },
      {
        path: 'ad-fraud-detection-for-advertisers',
        loadComponent: () =>
          import('./pages/ad-fraud-detection-for-advertisers/ad-fraud-detection-for-advertisers.component').then(
            (m) => m.AdFraudDetectionForAdvertisersComponent
          ),
        data: {
          name: 'Ad Fraud Detection for Advertisers',
          title: 'Ad Fraud Detection for Advertisers',
          group: 'Use Cases'
        }
      },
      {
        path: 'advanced-proxy-detection',
        loadComponent: () =>
          import('./pages/advanced-proxy-detection/advanced-proxy-detection.component').then(
            (m) => m.AdvancedProxyDetectionComponent
          ),
        data: {
          name: 'Advanced Proxy Detection',
          title: 'Advanced Proxy Detection',
          group: 'Use Cases'
        }
      },
      {
        path: 'affiliate-spytools-defense',
        loadComponent: () =>
          import('./pages/affiliate-spytools-defense/affiliate-spytools-defense.component').then(
            (m) => m.AffiliateSpytoolsDefenseComponent
          ),
        data: {
          name: 'Affiliate Spytools Defense',
          title: 'Affiliate Spytools Defense',
          hide: true,
          group: 'Use Cases'
        }
      },
      {
        path: 'scraping-prevention',
        loadComponent: () =>
          import('./pages/scraping-prevention/scraping-prevention.component').then(
            (m) => m.ScrapingPreventionComponent
          ),
        data: {
          name: 'Scraping Prevention',
          title: 'Scraping Prevention',
          group: 'Use Cases'
        }
      },
      {
        path: 'true-ua',
        loadComponent: () => import('./pages/true-ua/true-ua.component').then((m) => m.TrueUAComponent),
        data: {
          name: 'User-Agent Intelligence: TrueUA',
          title: 'User-Agent Intelligence: TrueUA',
          group: 'Use Cases'
        }
      },
      {
        path: 'email-verification',
        resolve: {
          tokenResponse: EmailTokenResolve
        },
        loadComponent: () =>
          import('./pages/email-verification/email-verification.component').then((m) => m.EmailVerificationComponent),
        data: {
          name: 'Email Verification',
          hide: true,
          title: 'Email Verification'
        }
      },
      {
        path: 'email-verification/:token',
        resolve: {
          tokenResponse: EmailTokenResolve
        },
        loadComponent: () => import('./pages/home/home.component').then((m) => m.HomeComponent),
        data: {
          name: 'Email Verification',
          hide: true,
          title: 'Email Verification'
        }
      },
      {
        path: 'password-reset/:uid/:token',
        resolve: {
          tokenResponse: PasswordResetTokenResolve
        },
        loadComponent: () => import('./pages/home/home.component').then((m) => m.HomeComponent),
        data: {
          hide: true
        }
      }
    ]
  },

  // Not found
  {
    path: '**',
    loadComponent: () => import('./pages/page-not-found/page-not-found.component').then((m) => m.PageNotFoundComponent)
  }
];
