import { Injectable } from '@angular/core';
import { SentryService } from '@adscore/adscore-frontend-common';
import { DataService } from '@adscore/adscore-frontend-common';

declare const numbro: any;

@Injectable()
export class NumberFormatService {
  constructor(private sentryService: SentryService, private dataService: DataService) {}

  default(x: any): string {
    let r;

    try {
      r = numbro(x)
        .format({
          average: true,
          mantissa: 2,
          trimMantissa: true
        })
        .toUpperCase();
    } catch (ex) {
      r = '0';
      const errorDataReq = this.dataService.get('reportRequestBody');
      const errorDataRes = this.dataService.get('reportResponseBody');
      if (errorDataReq && errorDataRes) {
        this.sentryService.logError(new Error(`Unexpected argument: ${x}, ${errorDataReq}, ${errorDataRes}`));
      }
    }

    return r;
  }

  // don't restrict decimals like the default one
  decimalSeparatorOnly(x: any): string {
    return ('' + x).split('.').join('<span class="decimal-separator">.</span>');
  }

  defaultDecimalSeparator(x: any): string {
    return this.decimalSeparatorOnly(this.default(x));
  }

  thousands(x: any): string {
    let r;

    try {
      r = numbro(x).format({
        thousandSeparated: true
      });
    } catch (ex) {
      r = '0';

      this.sentryService.logError(new Error(`Unexpected argument: ${x}`));
    }

    return r;
  }

  currency(x: any): string {
    let r;

    try {
      r = numbro(x).format({
        thousandSeparated: true,
        mantissa: 2
      });
    } catch (ex) {
      r = '0.00';
      this.sentryService.logError(new Error(`Unexpected argument: ${x}`));
    }

    return r;
  }
}
