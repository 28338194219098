import { catchError, first, take, takeUntil } from 'rxjs/operators';
import { Component, ViewChild, OnInit, OnDestroy, ApplicationRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  DataService,
  IError,
  InputComponent,
  ValidationService,
  ResetPasswordModalService,
  NEVER_TIMEOUT,
  LogLevel,
  LogMessage,
  LogService,
  Button,
  AuthService,
  SettingsService,
  StorageService,
  redirect
} from '@adscore/adscore-frontend-common';
import { Subscription, Observable, Subject, lastValueFrom } from 'rxjs';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertIcon } from 'sweetalert2';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AppStateService } from 'app/store/app-state';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {
  // output current year inside footer
  log$: Subscription;
  tag = 'LayoutComponent';
  today: number = Date.now();
  password = { value: '' };
  email = { value: '' };
  msgs: LogMessage[];
  loggedIn = false;
  name = '';
  rememberMe = false;
  pageCount = 0;
  loading = 0;
  scrollTimeout: number | null;
  sessionRefreshInterval = 0;
  errors = 0;
  isFaq$: Observable<boolean>;
  isBrowser: boolean;

  // modal
  modal = {
    show: false,
    title: 'test',
    subtitle: 'subtitle',
    body: '<div class="text-secondary fs-2">test</div>',
    closeButton: new Button({
      text: 'Close',
      icon: 'icon-close'
    })
  };

  swalRegistrationFeedback: {
    title: string | null;
    body: string | null;
    type: SweetAlertIcon | null;
  } = {
    body: null,
    title: null,
    type: null
  };

  @ViewChild('registrationFeedbackSwal', { static: true })
  private registrationFeedback: SwalComponent;

  @ViewChild('emailInput') emailInput: InputComponent;
  @ViewChild('passwordInput') passwordInput: InputComponent;

  faCheck = faCheck;

  appState$ = this.appStateService.appState$;

  private destroy$ = new Subject<boolean>();

  constructor(
    @Inject(PLATFORM_ID) platformId: object,
    public logService: LogService,
    public dataService: DataService,
    public router: Router,
    private validationService: ValidationService,
    public settings: SettingsService,
    public readonly swalTargets: SwalPortalTargets,
    private authService: AuthService,
    private storageService: StorageService,
    private app: ApplicationRef,
    private changeDetectorRef: ChangeDetectorRef,
    private appStateService: AppStateService,
    private resetPasswordModalService: ResetPasswordModalService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  launchRegistrationFeedbackModal() {
    const title = this.dataService.pop('homepage-registration-feedback-title');
    const body = this.dataService.pop('homepage-registration-feedback-body');
    const type = this.dataService.pop('homepage-registration-feedback');

    this.swalRegistrationFeedback = {
      title,
      body,
      type
    };
    this.registrationFeedback.fire();
  }

  ngOnInit() {
    this.log$ = this.dataService
      .updatables('logService')
      .observable.pipe(takeUntil(this.destroy$))
      .subscribe((msgs) => {
        this.msgs = msgs;
        this.changeDetectorRef.detectChanges();
      });

    if (this.isBrowser) {
      this.app.isStable
        .pipe(
          first((stable) => stable),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.refreshLoginState();
          this.sessionRefreshInterval = window.setInterval(() => {
            this.refreshLoginState();
          }, 10000);
        });
    }
  }

  refreshLoginState() {
    this.authService
      .getSession()
      .pipe(
        catchError(() => {
          this.loggedIn = false;
          this.changeDetectorRef.detectChanges();
          return [];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((session) => {
        this.loggedIn = true;
        this.name = session.user.first_name;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    delete (<any>globalThis).resetPasswordAfterManyTries;
    if (typeof this.scrollTimeout === 'number') {
      clearTimeout(this.scrollTimeout);
    }
    if (this.sessionRefreshInterval) {
      window.clearInterval(this.sessionRefreshInterval);
    }
  }

  activate() {
    if (!this.isBrowser) {
      return;
    }
    const loaded = () => {
      this.loading = 0;
      if (!location.href.includes('/faq/section')) {
        this.scrollTop();
      }
      const showModal = this.dataService.pop('homepage-modal');
      const registrationFeedbackModal = this.dataService.get('homepage-registration-feedback');
      // AUTO LAUNCH REGISTRATION FEEDBACK MODAL
      // - using sweetalert modal for the registration feedback
      if (registrationFeedbackModal) {
        this.launchRegistrationFeedbackModal();
        // AUTO LAUNCH REGULAR MODAL
        // - using sweetalert modal for the registration feedback
      } else if (showModal) {
        this.modal.show = true;
        this.modal.title = this.dataService.pop('homepage-modal-title');
        this.modal.subtitle = this.dataService.pop('homepage-modal-subtitle');
        this.modal.body = this.dataService.pop('homepage-modal-body');
      }
    };
    // don't introduce an extra delay on the first page load
    if (this.pageCount++ > 0) {
      this.loading = 1;
      window.setTimeout(() => {
        this.loading = 2;
        window.setTimeout(loaded, 150);
      }, 150);
    } else {
      loaded();
    }
  }

  scrollTop() {
    if (this.dataService.pop('homepage-disable-auto-scroll')) {
      return;
    }

    const scroll = globalThis.scroll || globalThis.scrollTo;
    try {
      scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (ex) {
      // fallback for devices without scroll and scrollTo support
      let y = globalThis.document?.documentElement.scrollTop || globalThis.document?.body.scrollTop;
      const timestep = 16;
      let count = 20;
      const yStep = y / count;
      const move = () => {
        y -= yStep;
        if (y < 1) {
          y = 0;
        }
        if (globalThis.document) {
          document.documentElement.scrollTop = document.body.scrollTop = y;
        }
        if (y !== 0 && --count) {
          this.scrollTimeout = window.setTimeout(move, timestep);
        } else {
          this.scrollTimeout = null;
        }
      };

      if (typeof this.scrollTimeout === 'number') {
        clearTimeout(this.scrollTimeout);
      }
      this.scrollTimeout = window.setTimeout(move, 100);
    }
  }

  async login() {
    const errorTitle = 'Login';
    const timeout = 0; // use default one
    const tag = 'HomePageComponent - Login';
    let errorHtml = '<ul>';
    let errorCount = 0;

    const appState = await lastValueFrom(this.appStateService.appState$.pipe(take(1)));
    if (appState.waitingLogin) {
      return;
    }

    // remove all previous error messages by tag
    this.logService.removeMessagesByTag(tag, LogLevel.Error);

    const errors = this.validationService.email(this.emailInput.getValue() as string).map((err) => err.getMsg());

    errorCount += errors.length;
    errorHtml += errors.map((err) => '<li>' + err + '</li>').join('');

    if (this.validationService.isBlank(this.passwordInput.getValue() as string)) {
      errorCount++;
      errorHtml += `<li>Please fill in your Password</li>`;
    }

    if (errorCount) {
      if (errorCount > 1) {
        errorHtml = '<div class="text-heavy">Please fix the following errors:</div>' + errorHtml;
      } else {
        errorHtml = '<div class="text-heavy">Please fix the following error:</div>' + errorHtml;
      }
      errorHtml += '</ul>';

      this.logService.addMessage(errorTitle, errorHtml, LogLevel.Error, timeout, tag);
      return;
    }

    // avoid sending the login request many times on multiple Enter presses or
    // button clicks
    this.appStateService.setAppStateData({ waitingLogin: true });

    this.logService.removeMessagesByTag(this.tag);

    this.authService
      .login(this.emailInput.getValue() as string, this.passwordInput.getValue() as string, this.rememberMe)
      .pipe(
        catchError((requestErrors) => {
          let html =
            'The following errors occurred:<ul>' +
            requestErrors.map((e: HttpErrorResponse) => '<li>' + e.message + '</li>') +
            '</ul>';

          if (++this.errors >= 2) {
            html += `
            <div class="m-t-1-25 p-t-1-25 text-heavy b-t b-primary-dark">
              Trouble logging in?
            </div>
          `;
          }

          this.appStateService.setAppStateData({ waitingLogin: false });
          this.logService.addMessageUnique(
            'Login',
            html,
            LogLevel.Error,
            this.errors >= 2 ? NEVER_TIMEOUT : 0,
            this.tag,
            false,
            false,
            false,
            this.errors >= 2
          );
          return [];
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.errors = 0;
        this.appStateService.setAppStateData({ waitingLogin: false });
        this.logService.removeMessagesByTag('logout-sticky-message');
        this.logService.removeMessagesByTag('password-reset');
        let redirectTo = '/dashboard';
        if (this.isBrowser) {
          redirectTo = this.storageService.get('redirect-after-login') || '';
          if (redirectTo) {
            try {
              redirectTo = JSON.parse(redirectTo);
            } catch (e) {
              console.warn(`Error while running JSON.parse on ${redirectTo}`, e);
            }
            this.storageService.remove('redirect-after-login');
          } else {
            redirectTo = '/dashboard';
          }
        }
        // Use location instead of Angular routing, because dashboard is another project
        redirect(redirectTo);
      });
  }

  logout(): void {
    this.storageService.remove('redirect-after-login');
    this.authService
      .logout()
      .pipe(
        catchError((errors: IError[]) => {
          this.loggedIn = false;
          return this.logService.notifyUserAboutErrors(errors);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.loggedIn = false;
      });
  }

  removeMessage(message: LogMessage): void {
    this.logService.removeMessages(message.text, message.level);
  }

  getMessageLevelName(level: number): string {
    return this.logService.getLevelName(level);
  }

  register() {
    this.dataService.updatables('registrationEmailStartingValue').value = this.emailInput.getValue();
    this.dataService.updatables('registrationPasswordStartingValue').value = this.passwordInput.getValue();
    if (!this.router.isActive('register', true)) {
      // prevent autoscrolling after navigation, because we're going to scroll to email or password input
      this.dataService.set('homepage-disable-auto-scroll', true);
      this.router.navigate(['register']);
    }
    return false;
  }

  openPasswordResetModal() {
    this.resetPasswordModalService.show();
  }

  enterKeyPress() {
    if (this.emailInput.getValue() && this.passwordInput.getValue()) {
      this.login();
    }
  }

  toggleMobileSidebar() {
    this.settings.layout.asideToggledMobile = !this.settings.layout.asideToggledMobile;
  }
}
