import { Inject, PLATFORM_ID, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// prism
import 'prismjs';

// Add line-numbers support to Prism highlighted code
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';

declare let Prism: any;

@Injectable()
export class PrismService {
  isBrowser: boolean;
  constructor(@Inject(PLATFORM_ID) platformId: object) {
    this.isBrowser = isPlatformBrowser(platformId);
    Prism.manual = true;
  }

  highlightAll() {
    if (!this.isBrowser) {
      return;
    }
    Prism.highlightAll();
  }

  highlight(text: string, grammar: any, language: string) {
    return Prism.highlight(text, grammar, language);
  }
}
