<section id="cta" class="section">
  <div class="section--container">
    <div class="row justify-content-center">
      <div class="col-md-6 text-center">
        <h3 class="fs-1-25 text-heavy lh-1 text-secondary m-b-2-5">
          {{ ctaText || 'Stop buying fraudulent traffic.' }}
        </h3>
        <a
          routerLink="{{ link || '/register' }}"
          class="button cta text-white background-primary m-b-1"
          [attr.data-cta]="ctaPosition"
        >
          {{ buttonText || 'Get Started' }}
        </a>
      </div>
    </div>
  </div>
</section>
