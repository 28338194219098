import { ErrorHandler, Injectable } from '@angular/core';
import { SentryService } from '@adscore/adscore-frontend-common';

@Injectable()
export class LoadingChunkErrorHandlerService implements ErrorHandler {
  private unloading = false;

  constructor(private sentryService: SentryService) {
    window.addEventListener('beforeunload', () => {
      this.unloading = true;
    });
  }

  handleError(error: any): void {
    if (this.unloading) {
      // `beforeunload` event was fired, we don't want to handle chunk loading errors now
      return;
    }
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      console.warn('Landing - reload page after chunk loading failed');
      window.location.reload();
    } else {
      console.error(error);
      this.sentryService.logError(error);
    }
  }
}
