import { catchError, tap } from 'rxjs/operators';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { DataService } from '@adscore/adscore-frontend-common';
import { of } from 'rxjs';
import { UserService } from '@adscore/adscore-frontend-common';
import { LogService } from '@adscore/adscore-frontend-common';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class PasswordResetTokenResolve implements Resolve<any> {
  isBrowser: boolean;

  constructor(
    private router: Router,
    private dataService: DataService,
    private userService: UserService,
    private logService: LogService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    if (!this.isBrowser) {
      return new Promise((resolve) => {
        resolve(null);
      });
    }

    const uid = route.params.uid;
    const token = route.params.token;
    if (!uid || !token) {
      this.router.navigate(['']);
      return new Promise((resolve) => {
        resolve(true);
      });
    }

    this.dataService.set('password-reset-uid', uid);
    this.dataService.set('password-reset-token', token);

    return this.userService
      .validatePasswordResetToken(uid, token)
      .pipe(
        tap(() => {
          this.dataService.set('show-password-reset-modal', true);
          this.router.navigate(['']);
        })
      )
      .pipe(
        catchError((errors) => {
          this.logService.notifyUserAboutErrors(errors);
          this.router.navigate(['']);

          return of(true);
        })
      )
      .toPromise();
  }
}
